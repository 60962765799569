import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
// importing the css
import css from "../../style/booking.module.css";
// importing components
import { Modal, Box } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm2 from "../../components/stripe/PaymentForm2";
import { bookingIfCardSaved } from "../../store/actions/paymentActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { updateBooking } from "../../store/actions/bookingActions";
import DeliveryPriceBreakdown from "../../components/Booking/BreakDownOfFare_Delivery";
import MoovingPriceBreakdown from "../../components/Booking/BreakDownOfFare_Mooving";
import { stripeTestPromise } from "../../store/constants/baseUrl";
import ModalComponent from "../../components/Modals/Modal";
import CouponCode from "./CouponCode";

const PaymentScreen = ({
  id,
  type,
  bookingTotalModel,
  distanceInKm,
  statesToBookingObj,
  handleUpdateBookingObject,
  userInfo,
  setShowLottie,
  setShowPaymentMessage,
  coupon,
}) => {
  // dispatch redux actions
  const dispatch = useDispatch();
  // history for routing
  const history = useNavigate();
  // state to restrict multipel clicks
  const [buttonClicked, setButtonClicked] = useState(false);
  // State to open CC modal
  const [openCreditCardModal, setopenCreditCardModal] = useState(false);
  // State to open CC response modal
  const [openCreditCardResponseModal, setopenCreditCardResponseModal] =
    useState(false);

  // State to open Booking error modal
  const [openBookingErrorModal, setopenBookingErrorModal] = useState(false);
  //toggle for card window name
  const [firstNameToggle, setfirstNameToggle] = useState(true);
  //payment credentials
  const [fullName, setfullName] = useState("");

  const toggleLoading = (val) => {
    setShowLottie(val);
    setShowPaymentMessage(val);
  };
  // function to close CC modal
  const handleCloseCardModal = () => {
    try {
      setopenCreditCardModal(false);
      setfirstNameToggle(true);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  //form ID control
  const [formID, setformID] = useState("");
  // function to place order and make payment
  const handlePlaceOrder = () => {
    handleCloseCardAddedModal();
    let user = localStorage.getItem("wemoovezUserProfile");
    if (!user) {
      history("/Login");
      return;
    }
    user = JSON.parse(user);
    try {
      if (user?.hasCard) {
        setShowLottie(true);
        setShowPaymentMessage(true);
        dispatch(
          bookingIfCardSaved(
            id,
            type,
            history,
            toggleLoading,
            handleShowBookingErrorModal,
            3
          )
        );
      } else setopenCreditCardModal(true);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      toggleLoading(false);
    }
  };
  // return to confirmation screen
  const handleGoToConfirmOrderScreen = () => {
    try {
      setShowLottie(true);
      updateBooking(
        id,
        {
          ...statesToBookingObj(),
          tripStatus: "Confirmation",
        },
        handleUpdateBookingObject,
        setShowLottie
      );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      setShowLottie(false);
    }
  };

  const handleShowCardAddedModal = () => {
    setopenCreditCardResponseModal(true);
  };

  const handleCloseCardAddedModal = () => {
    setopenCreditCardResponseModal(false);
  };

  const handleShowBookingErrorModal = () => {
    setopenBookingErrorModal(true);
  };

  const handleCloseBookingErrorModal = () => {
    setopenBookingErrorModal(false);
  };

  //Modal styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    try {
      let makeBtnClickable;

      if (buttonClicked) {
        makeBtnClickable = setTimeout(() => {
          setButtonClicked(false);
        }, 10000);
      }
      return () => clearTimeout(makeBtnClickable);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [buttonClicked]);

  return (
    <div style={{ padding: "40px 0" }}>
      <ModalComponent
        text={
          "Card added successfully, click request now to proceed with your booking."
        }
        openState={openCreditCardResponseModal}
        closeFunction={handleCloseCardAddedModal}
        btnText1={"Request now"}
        btnClickFunc1={handlePlaceOrder}
        btnText2={"Close"}
        btnClickFunc2={handleCloseCardAddedModal}
      />
      <ModalComponent
        text={"Something went wrong, please try again or contact our support."}
        openState={openBookingErrorModal}
        closeFunction={handleCloseBookingErrorModal}
        btnText1={"Contact Us"}
        btnClickFunc1={() => history("/ContactUs")}
        btnText2={"Close"}
        btnClickFunc2={handleCloseBookingErrorModal}
      />
      <div className={css?.paper}>
        {["Delivery"].includes(type) ? (
          <DeliveryPriceBreakdown
            distanceInKm={distanceInKm}
            bookingTotalModel={bookingTotalModel}
          />
        ) : (
          <MoovingPriceBreakdown bookingTotalModel={bookingTotalModel} />
        )}
        <Modal
          open={openCreditCardModal}
          onClose={handleCloseCardModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={css?.cardWindowMainDiv}>
            <div className={css?.cardPaymentProcess}>Payment Process</div>
            <div className={css?.checkoutInfo}>Checkout Details</div>
            <div className={css?.cardWindowInfoText}>
              Please add a payment method so we can pair your job to a moover.
              Charges apply only after your job has been successfully completed.
              This is a secure AES-256 encrypted payment.
            </div>
            <div className={css?.accountDetailsBoxInputBox}>
              {firstNameToggle ? (
                <span className={css?.accountDetailsBoxInputBoxLabel}>
                  {fullName
                    ? fullName
                    : userInfo?.firstName + " " + userInfo?.lastName}
                </span>
              ) : (
                <input
                  value={fullName}
                  onChange={(e) => setfullName(e.target.value)}
                  className={css?.accountDetailsBoxInputBoxInput}
                ></input>
              )}
              <span
                className={css?.accountDetailsBoxInputBoxEditIcon}
                onClick={() => setfirstNameToggle(!firstNameToggle)}
              >
                <i class="far fa-edit"></i>
              </span>
            </div>
            <div className={css?.accountDetailsBoxInputBox}>
              <Elements stripe={stripeTestPromise}>
                <PaymentForm2
                  userId={userInfo?.id}
                  // bookingId={id}
                  // type={type}
                  handleCloseCardModal={handleCloseCardModal}
                  handleShowCardAddedModal={handleShowCardAddedModal}
                  handleShowBookingErrorModal={handleShowBookingErrorModal}
                  setShowLottie={toggleLoading}
                  submitWrapper={(cb, e) => {
                    e.preventDefault();
                    if (!buttonClicked) {
                      toggleLoading(true);
                      setButtonClicked(true);
                      cb(e);
                    }
                  }}
                />
              </Elements>
            </div>
            <div className={css?.payNowButtons}>
              <button
                onClick={handleCloseCardModal}
                className={css?.editTripbtn}
                style={{ marginRight: 10 }}
              >
                Cancel
              </button>
              <button
                form={formID}
                onClick={() => {
                  try {
                    setformID("payment-in-booking");
                  } catch (error) {
                    Sentry.captureException(error);
                    console.log(error);
                  }
                }}
                className={css?.confirmPickupButton}
              >
                Add card
              </button>
            </div>
          </Box>
        </Modal>
        <CouponCode
          coupon={coupon}
          handleUpdateBookingObject={handleUpdateBookingObject}
        />
        <span className={css?.priceBreakdownCloseButton}>
          <button
            onClick={handlePlaceOrder}
            className={css?.confirmPickupButton}
          >
            Book now
          </button>
          <button
            onClick={handleGoToConfirmOrderScreen}
            className={css?.editTripbtn}
          >
            Edit job
          </button>
        </span>
      </div>
    </div>
  );
};

export default PaymentScreen;
