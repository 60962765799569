import axios from "axios";
import * as Sentry from "@sentry/react";
import baseUrl from "../constants/baseUrl";
import {
  GET_PAYMENT_METHOD_FAIL,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_REQUEST,
} from "../constants/paymentMethodConstants";
import { updateUserCardStatus } from "../../Utilities/general";

export const bookingIfCardSaved =
  (
    bookingId,
    type,
    history,
    toggleLoading,
    handleShowBookingErrorModal,
    retries
  ) =>
  async (dispatch) => {
    try {
      await axios.get(
        `${baseUrl}BookingsAPI/holdPayment?bookingId=${bookingId}&currency=cad`,
        { timeout: 5000 }
      );
      localStorage.removeItem(`wemoovez${type}Object`);
      localStorage.removeItem(`confirm1mover`);
      toggleLoading(false);
      history(`/thankyou`);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      if (!retries) {
        toggleLoading(false);
        handleShowBookingErrorModal();
      } else
        dispatch(
          bookingIfCardSaved(
            bookingId,
            type,
            history,
            toggleLoading,
            handleShowBookingErrorModal,
            retries - 1
          )
        );
    }
  };

export const bookingIfCardNotSaved =
  (
    token,
    modalFields,
    setModalFields,
    setShowLottie,
    handleCloseCardModal,
    handleShowCardAddedModal,
    handleShowBookingErrorModal,
    retries
  ) =>
  async (dispatch) => {
    try {
      let user = localStorage.getItem("wemoovezUserProfile");
      user = JSON.parse(user);
      await axios.post(
        `${baseUrl}BookingsAPI/AddingStripeToUserProfile?userId=${user.id}&token=${token}`,
        { timeout: 5000 }
      );
      localStorage.setItem(
        "wemoovezUserProfile",
        JSON.stringify({ ...user, hasCard: true })
      );
      setShowLottie(false);
      handleCloseCardModal();
      handleShowCardAddedModal();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      if (!retries) {
        setModalFields({
          ...modalFields,
          open: true,
          text: error?.response?.data?.message ?? "Couldn't process payment.",
        });
        setShowLottie(false);
        handleCloseCardModal();
        handleShowBookingErrorModal();
      } else {
        dispatch(
          bookingIfCardNotSaved(
            token,
            modalFields,
            setModalFields,
            setShowLottie,
            handleCloseCardModal,
            handleShowCardAddedModal,
            handleShowBookingErrorModal,
            retries - 1
          )
        );
      }
    }
  };

// user payment methods action
// this action is called in user profile pages
export const userPaymentMethods = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PAYMENT_METHOD_REQUEST,
    });
    const { data } = await axios.get(
      `${baseUrl}ProfileDetailAPI/UserPaymentMethods?id=${userId}`
    );
    const hasCards = data.length ? true : false;
    updateUserCardStatus(hasCards);
    dispatch({
      type: GET_PAYMENT_METHOD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    dispatch({
      type: GET_PAYMENT_METHOD_FAIL,
      payload: error,
    });
  }
};

// set default payment methods action
// this action is called in user profile pages
export const setDefaultPaymentMethod = (userId, cardId) => async (dispatch) => {
  try {
    console.log("cardId from frontend", cardId);
    const res = await axios.get(
      `${baseUrl}ProfileDetailAPI/SetDefaultPaymentMethod?cardId=${cardId}`
    );
    console.log("user payment methods from api", res?.data);
    const { data } = await axios.get(
      `${baseUrl}ProfileDetailAPI/UserPaymentMethods?id=${userId}`
    );
    console.log("user payment methods from api", data);
    dispatch({
      type: GET_PAYMENT_METHOD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

// delete payment methods action
// this action is called in user profile pages
export const deletePaymentMethod = (userId, cardId) => async (dispatch) => {
  try {
    console.log("cardId from frontend", cardId);
    const res = await axios.get(
      `${baseUrl}ProfileDetailAPI/RemovePaymentMethod?cardId=${cardId}`
    );
    updateUserCardStatus(false);
    const { data } = await axios.get(
      `${baseUrl}ProfileDetailAPI/UserPaymentMethods?id=${userId}`
    );
    console.log("user payment methods from api", data);
    dispatch({
      type: GET_PAYMENT_METHOD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};
