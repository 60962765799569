import React from "react";
import css from "../../style/booking.module.css";
import { BiPlusMedical } from "react-icons/bi";
import { FaMinus } from "react-icons/fa";
import { CustomTooltip } from "../Tooltip/tooltip";

const textArr = [
  {
    isSubbullet: false,
    text: "Please select the approximate number of hours your job will require.",
  },
  {
    isSubbullet: false,
    text: "All Mover jobs include an hourly rate and 30 minutes of travel time (the time it takes for the mover to arrive at the provided address). Extra travel time applies to jobs outside the city.",
  },
  { isSubbullet: false, text: "Here are our hourly labor rates:" },
  { isSubbullet: true, text: "1 mover: $50/hr" },
  { isSubbullet: true, text: "2 movers: $90/hr" },
  { isSubbullet: true, text: "3 movers: $130/hr" },
  { isSubbullet: true, text: "4 movers: $170/hr" },
  {
    isSubbullet: false,
    text: "For example, if you require 1 mover for 1 hour (labour time) + 30 minutes (travel time), the calculation would be: $50/hr x 1.5 hours = $75.00 + $2.66 (Service Fee) + $3.75 GST = $81.41 (Total).",
  },
];
const TotalHours = ({
  numberOfHours,
  type,
  min,
  max,
  increment,
  decrement,
  increment2,
}) => {
  return (
    <div className={css?.counterMainDiv}>
      <div className={css?.Level2Heading}>
        {type === "Delivery" ? <># of Hours </> : <># of Hours </>}
        <CustomTooltip
          title={
            <div className={css?.tooltipMainDiv}>
              {textArr.map((bullet, index) => (
                <div
                  className={css?.tooltipBullet}
                  style={
                    bullet.isSubbullet
                      ? { marginLeft: "40px", width: "calc(100% - 40px)" }
                      : {}
                  }
                >
                  <div className={css?.tooltipDotBox}>
                    <div className={css?.tooltipDot}>.</div>
                  </div>
                  <div className={css?.tooltipText}>{bullet.text}</div>
                </div>
              ))}
            </div>
          }
        >
          <img
            src="images/tooltip.svg"
            style={{
              display: "inline",
              width: 21,
              marginLeft: 3,
              cursor: "pointer",
            }}
            alt=""
          />
        </CustomTooltip>
      </div>
      <div className={css?.counterStrip}>
        <input
          defaultValue={1}
          value={numberOfHours}
          min={min}
          max={max}
          className={css?.counterInput}
        ></input>
        {numberOfHours > min ? (
          <div
            onClick={() => numberOfHours > min && decrement()}
            className={css?.counterButtons}
          >
            <FaMinus />
          </div>
        ) : (
          <div
            onClick={() => numberOfHours > min && decrement()}
            style={{ backgroundColor: "#9e9ac7" }}
            className={css?.counterButtons}
          >
            <FaMinus />
          </div>
        )}
        {numberOfHours < max ? (
          <div
            className={css?.counterButtons}
            onClick={() => {
              increment();
            }}
          >
            <BiPlusMedical />
          </div>
        ) : (
          <div
            className={css?.counterButtons}
            style={{ backgroundColor: "#9e9ac7" }}
            onClick={() => numberOfHours < max && increment()}
          >
            <BiPlusMedical />
          </div>
        )}
      </div>
    </div>
  );
};

export default TotalHours;
